/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dataActions } from '../../../shared/store/data-Slice';
import { CloseBtn, GreenPointsInfoBg } from '../svg/AllSvg';
import { useTranslation } from 'react-i18next';

const GoGreenPointsInfo = () => {
    const goGreenPointsOpen: boolean = useSelector((state: any) => state.data.goGreenPointsOpen);
    const { t } = useTranslation('common');
    const ref: any = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setGoGreenPointsOpen(false));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setGoGreenPointsOpen(false));
        }
    };


    return (
        <>
            {goGreenPointsOpen &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] xs:mx-auto w-full xs:w-[345px] relative bg-white dark:bg-bgDarkDull rounded-[20px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)] p-[20px]" ref={ref}>
                            <div className='absolute top-[-0.5px] left-[-0.5px]'><GreenPointsInfoBg /></div>
                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px] rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" onClick={() => dispatch(dataActions.setGoGreenPointsOpen(false))}>
                                <CloseBtn className="min-w-[23px]" />
                            </button>

                            <div className='flex flex-col gap-[15px] pt-[180px]'>
                                <h3 className='text-primaryText dark:text-white font-bold max-w-[263px]'>{t("go_green_points.info.title")}</h3>
                                <div className='flex flex-col gap-[10px]'>
                                    <h6 className='text-blackDull dark:text-textBlackDull font-medium text-justify'>{t("go_green_points.info.line1")}</h6>
                                    <h6 className='text-blackDull dark:text-textBlackDull font-medium text-justify'>{t("go_green_points.info.line2")}</h6>
                                    <h6 className='text-blackDull dark:text-textBlackDull font-medium text-justify'>{t("go_green_points.info.line3")}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default GoGreenPointsInfo
export const url = {
    // baseUrl: "https://mmrc-dev-api.stagemyapp.com",
    baseUrl: "https://portal.mmrcl.com",
    // baseUrl: "https://mmrc-prod-api.stagemyapp.com",
    // baseUrl: "https://api.mmrcl.com",
    // domains: {
    //     main: "localhost",
    //     recruitment: "recruitment.localhost",
    //     vigilance: "vigilance.localhost",
    //     grievance: "grievance.localhost",
    //     corporate: "corporate.localhost",
    //     chatbot: "chatbot.localhost"
    // },
    // domains: {
    //     main: "mmrc-dev.localhost",
    //     recruitment: "recruitment-mmrc-dev.localhost",
    //     vigilance: "vigilance-mmrc-dev.localhost",
    //     grievance: "grievance-mmrc-dev.localhost",
    //     corporate: "corporate-mmrc-dev.localhost",
    //     chatbot: "chatbot-mmrc-dev.localhost"
    // },
    // domains: {
    //     main: "mmrc-dev.stagemyapp.com",
    //     recruitment: "recruitment-mmrc-dev.stagemyapp.com",
    //     vigilance: "vigilance-mmrc-dev.stagemyapp.com",
    //     grievance: "grievance-mmrc-dev.stagemyapp.com",
    //     corporate: "corporate-mmrc-dev.stagemyapp.com",
    //     chatbot: "chatbot-mmrc-dev.stagemyapp.com"
    // },  
    domains: {
        main: "mmrcl.com",
        recruitment: "recruitment.mmrcl.com",
        vigilance: "vigilance.mmrcl.com",
        grievance: "grievance.mmrcl.com",
        corporate: "corporate.mmrcl.com",
        chatbot: "chatbot.mmrcl.com"
    },
    // subdomain: {
    //     main: "mmrc-dev",
    //     recruitment: "recruitment-mmrc-dev",
    //     vigilance: "vigilance-mmrc-dev",
    //     grievance: "grievance-mmrc-dev",
    //     corporate: "corporate-mmrc-dev",
    //     chatbot: "chatbot-mmrc-dev"
    // },
    subdomain: {
        main: "mmrc",
        recruitment: "recruitment",
        vigilance: "vigilance",
        grievance: "grievance",
        corporate: "corporate",
        chatbot: "chatbot"
    },
    env: "prod",
    line_code: "LN3",
    socketUrl: "wss://contaqueai.contaquecloud.com/socket/ws/",
    bucketUrl: "https://mmrcl-development.s3.amazonaws.com",
    candidate_registeration: "/candidate-registeration/",
    login: "/api/v2/login/",
    registeration: "/api/v2/registration/",
    verifyEmail: "/verify-account/",
    sendOtp: "/send-otp/",
    category: "/categories/",
    nationality: "/country-list/",
    state: "/state-list/",
    qualificationList: "/qualification-list/",
    candidate_detail: "/api/candidate-details/",
    admin_login: "/api/v2/admin-login/",
    createAd: "/api/advertisement/",
    publicAd: "/api/advertisement-public-list/",
    publicJob: "/api/job-public-list/",
    createJob: "/api/job/",
    locationList: "/locations/",
    deptList: "/departments/",
    gradeList: "/grade-pay-scales/",
    recruitmentFooter: "/recruitment-footer-api/",
    jobCategory: "/job-by-category/",
    relatedJob: "/api/related-jobs/",
    applyJob: "/api/apply-jobs/",
    candidateList: "/api/job-applied-candidate-list/",
    empNature: "/emp-nature-list/",
    applyStatus: "/apply-status-tab-list/",
    hrStatistics: "/statistics/",
    applyedInfo: "/job-applied-instance/",
    deleteFile: "/delete-pdf/",
    stationDetail: "/api/v2/station-detail/",
    searchStation: "/api/v2/station-by-keyword/",
    travel_distance_time_fare: "/api/v2/fare-calculator/",
    notice: "/api/v2/notification/passenger/",
    facility: "/api/v2/filter-category-list/",
    search_by_facility_station: "/api/v2/search-by-facility-category/",
    menu: "/api/v2/menu-list/",
    line_list: "/api/v2/line-list/",
    storeCategory: "/api/v2/store-categories/",
    store: "/api/v2/station-stores/",
    station_route: "/api/v2/journey-route/",
    searchStOrLt: "/api/v2/station-localities-by-keyword/",
    pages: "/api/v2/passenger/",
    lost_and_found: "/api/v2/lost-found-items-list/",
    item_category_list: "/api/v2/item-category-list/",
    item_sub_category_list: "/api/v2/item-sub-categories/",
    report_lost_items: "/api/v2/report-lost-items/",
    applied_candidate: "/job-applied-candidate-detail/",
    create_complaint: "/api/v2/create-complaint/",
    agent_create_complaint: "/api/v2/create-agent-complaint/",
    get_exist_user: "/api/v2/get-user-exists-status/",
    complaint_category: "/api/v2/complaint-category-list/",
    dept: "/api/v2/complaint-department-list/",
    complaintStatistics: "/api/v2/complaint-dashboard-statistics/",
    resolvedComplaint: "/api/v2/complaint-list/",
    send_otp: "/api/v2/send-otp/",
    forgot_password: "/api/v2/forgot-password/",
    status_wise_complaint: "/api/v2/status-wise-complaint-list/",
    complaint_details: "/api/v2/complaint-details/",
    assign_complaint: "/api/v2/assign-complaint/",
    reslove_compaint: "/api/v2/mark-complaint-resolved/",
    refer_back_compaint: "/api/v2/refer-back-complaint/",
    add_remarks_compaint: "/api/v2/customer-add-remarks/",
    user_search: "/api/v2/user-department-list/",
    change_category: "/api/v2/change-complaint-category/",
    search_api: "/api/v2/search-passenger-page/",
    tours: "/api/v2/tourism/",
    download_form: "/download-applied-form/",
    contact_us: "/api/v2/contact-us/",
    create_ticket: "/api/v2/vigilance/create-ticket/",
    admin_create_ticket: "/api/v2/vigilance/admin-create-ticket/",
    grievance_visitors: "/api/v2/grievances-total-visitors/",
    st_status_statistics: "/api/v2/station-status-statistics/",
    updates: "/api/v2/line-details/",
    place_category: "/api/v2/near-by-filter-list/",
    near_by_places: "/api/v2/search-near-by-places/",
    fav_trips: "/api/v2/favorite-trips/",
    mark_unmark_trip: "/api/v2/mark-unmark-favorite-trip/",
    get_ticket_price: "/api/v2/get-ticket-price/",
    book_ticket: "/api/v2/tickets/book-ticket/",
    payment_page: "/api/v2/payment-page/",
    ticket_detail: "/api/v2/tickets/ticket-detail/",
    all_tickets: "/api/v2/tickets/passenger-ticket-list/",
    retry: "/api/v2/tickets/retry-ticket/",
    login_otp: "/api/v2/send-passenger-otp/",
    verify_login_otp: "/api/v2/verify-passenger-otp/",
    verify_passenger_account: "/api/v2/passenger-verify-account/",
    passenger_upd: "/api/v2/update-web-user-profile/",
    passenger_get: "/api/v2/get-web-user-profile/",
    transactions: "/api/v2/tickets/my-transactions/",
    lost_item: "/api/v2/lost-found/reported-item-list/",
    download_ticket: "/api/v2/tickets/download-ticket/",
    passenger_footer: "/api/v2/passenger-total-visitors/",
    metro_downtime: "/api/v2/metro-downtime",
    medical: "/api/v2/medical-details/",
    my_applied_jobs: "/my-applied-jobs-list/",
    download_applied_jobs: "/download-job-zipped-files/",
    download_applied_ad: "/download-adv-job-zipped-files/",
    passenger_visitor_count: "/passenger-visitor-ip/",
    grievance_visitor_count: "/grievance-visitor-ip/",
    recruitment_visitor_count: "/recruitment-visitor-ip/",
    download_applied_jobs_xlsx: "/export-applied-candidate-excel/",
    recruitment_send_otp: "/api/recruitment/send-login-otp/",
    recruitment_verify_otp: "/api/recruitment/verify-login-otp/",
    passenger_verify_profile: "/api/v2/edit-profile-verify/",
    get_sitemap: "/api/v1/sitemap/",
    transaction_details: "/api/v2/tickets/transactions-details/",
    vigilance_visitors: "/api/v2/vigilance/total-visitors/",
    vigilance_visitor_count: "/api/v2/vigilance/visitor-ip/",
    vigilance_statistics: "/api/v2/vigilance/dashboard-statistics/",
    vigilance_status_wise_complaint: "/api/v2/vigilance/status-wise-list/",
    vigilance_complaint_list: "/api/v2/vigilance/complaint-list/",
    vigilance_complaint_detail: "/api/v2/vigilance/complaint-details/",
    vigilance_resolve_complaint: "/api/v2/vigilance/mark-complaint-resolved/",
    vigilance_assign_complaint: "/api/v2/vigilance/update-ticket/",
    vigilance_user_search: "/api/v2/vigilance/user-department-list/",
    vigilance_add_remarks: "/api/v2/vigilance/add-ticket-remarks/",
    vigilance_reopen_remarks: "/api/v2/vigilance/reopen-resolved-complaint/",
    add_feedback: "/api/v2/create-passenger-feedback/",
    feedback_list: "/api/v2/feedback-listing/",
    feedback_details: "/api/v2/feedback-details/",
    feedback_status_change: "api/v2/mark-feedback-closed/",
    corporate_homepage: "/api/v2/corporate-homepage/",
    corporate_pages: "/api/v2/corporate/",
    corporate_search_api: "/api/v2/search-corporate-page/",
    corporate_visitor_count: "/corporate-visitor-ip/",
    corporate_footer: "/api/v2/corporate-total-visitors/",
    tendersList: "/api/v2/search-tenders-list/",
    tenderCategory: "/api/v2/tender-categories/",
    tenderSubCategory: "/api/v2/tender-sub-categories/",
    media_gallery: "/api/v2/media-gallery/",
    video_gallery: "/api/v2/media-video/",
    news_list: "/api/v2/news-list/",
    press_release: "/api/v2/press-release-list/",
    public_consultation: "/api/v2/public-consultation-list/",
    public_notice: "/api/v2/public-notice-list/",
    lottery_result: "/api/v2/corporate-lottery-list/",
    add_new_stop_word: "/api/v2/chatbot/create-stopword/",
    add_new_alert_word: "/api/v2/chatbot/create-alert-word/",
    chatbotStatistics: "/api/v2/chatbot/dashboard-statistics/",
    chatbot_queries: "/api/v2/chatbot/transcript-list/",
    alerts_list: "/api/v2/chatbot/transcript-threat-list/",
    stop_word_list: "/api/v2/chatbot/stopword-list/",
    alert_word_list: "/api/v2/chatbot/alert-word-list/",
    delete_word: "/api/v2/chatbot/delete-words/",
    banners: "/api/v2/banners/",
    store_category: "/api/v2/store-categories/",
    stores: "/api/v2/station-stores/",
    product_category: "/api/v2/store-product-categories/",
    store_products: "/api/v2/store-products/",
    store_details: "/api/v2/station-store-details/",
    property_list: "/api/v2/property-list/",
    property_type_list: "/api/v2/property-types-list/",
    location_list: "/api/v2/locations-list/",
    search_property: "/api/v2/search-property/"
};
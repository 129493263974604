/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import Main from "./routes/main/Main";
import Loader from "./components/common/layout/Loader";
import { useSelector } from "react-redux";
import { getApp } from "./shared/utils/helpers";
import Recruitment from "./routes/corporate/recruitment/Recruitment";
import Hiring from "./routes/corporate/hiring/Hiring";
import Vigilance from "./routes/corporate/vigilance/Vigilance";
import OtherWebLink from "./components/common/layout/OtherWebLink";
import Grievance from "./routes/corporate/grievance/Grievance";
import Chatbot from "./routes/chatbot/Chatbot";
import Corporate from "./routes/corporate/corporate/Corporate";
import { url } from "./shared/utils/url";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import CacheBuster from 'react-cache-buster';
import app from '../package.json';

const MainRouter = () => {
  const tenant: string = useSelector((state: any) => state.data.tenant);
  const theme: any = useSelector((state: any) => state.ui.theme);
  const webOpen: boolean = useSelector((state: any) => state.data.webOpen);
  let [loader, setLoader] = useState<any>(true);
  const [tenantData, setTenantData] = useState<any[]>([]);
  const Components: any = !loader && getApp(tenantData);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (webOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [webOpen]);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    !!theme && localStorage.setItem("theme", theme);
  }, [theme]);

  const getTenant = () => {
    // change live
    // for tenant setting
    let tenantList: any[] = url?.env === "prod" ? ([{ name: url?.subdomain?.recruitment, component: window?.location?.pathname.includes("/admin") ? Hiring : Recruitment }, { name: url?.subdomain?.vigilance, component: Vigilance }, { name: url?.subdomain?.grievance, component: Grievance }, { name: url?.subdomain?.corporate, component: Corporate }, { name: url?.subdomain?.chatbot, component: Chatbot }]) :
      ([{ name: url?.subdomain?.recruitment, component: window?.location?.pathname.includes("/admin") ? Hiring : Recruitment }, { name: url?.subdomain?.vigilance, component: Vigilance }, { name: url?.subdomain?.grievance, component: Grievance }, { name: url?.subdomain?.corporate, component: Corporate }, { name: url?.subdomain?.chatbot, component: Chatbot }, { name: url?.subdomain?.main, component: Main }]);
    // let tenantList: any[] = [{ name: url?.subdomain?.recruitment, component: window?.location?.pathname.includes("/admin") ? Hiring : Recruitment }, { name: url?.subdomain?.vigilance, component: Vigilance }, { name: url?.subdomain?.grievance, component: Grievance }, { name: url?.subdomain?.main, component: Main }];
    let arr: any[] = [];
    let tenant: any = "";
    for (let i = 0; i < tenantList?.length; i++) {
      const el = tenantList?.[i];

      if (window.location.hostname?.includes(el?.name)) {
        tenant = window.location.hostname?.replace(`${el?.name}.`, "")
      }

      let obj: any;
      if (!!tenant) {
        obj = {
          subdomain: el?.name + "." + tenant,
          app: el?.component,
          main: url?.env === "prod" ? false : ((el?.name === url?.subdomain?.main) ? true : false)
          // main: false
        }
      } else {
        obj = {
          subdomain: el?.name + "." + window.location.hostname,
          app: el?.component,
          main: url?.env === "prod" ? false : ((el?.name === url?.subdomain?.main) ? true : false)
          // main: false
        }
      }

      arr.push(obj)
    }

    if (url?.env === "prod") {
      arr.push({
        subdomain: !!tenant ? tenant : window.location.hostname,
        app: Main,
        main: true
      })
    }

    setTenantData(arr);
    setLoader(false);
  }

  useEffect(() => {
    getTenant();
  }, [])

  return (
    <>
      <CacheBuster
        currentVersion={app.version}
        isEnabled={true}
        reloadOnDowngrade={true}
        isVerboseMode={true}
        loadingComponent={<Loader />}
        metaFileDirectory={'.'}
        onCacheClear={(refreshCacheAndReload: () => Promise<void>) => { }}
      >
        <>
          {(!!tenant && tenant?.toString()?.includes(url?.subdomain?.recruitment)) && <Helmet>
            <title>{t("seo_friendly.recruitment_home.title")}</title>
            <meta name="description" content={t("seo_friendly.recruitment_home.desc")} />
            <meta name="keywords" content={t("seo_friendly.recruitment_home.keywords")} />
          </Helmet>}

          {(!!tenant && tenant?.toString()?.includes(url?.subdomain?.grievance)) && <Helmet>
            <title>{t("seo_friendly.grievance_home.title")}</title>
            <meta name="description" content={t("seo_friendly.grievance_home.desc")} />
            <meta name="keywords" content={t("seo_friendly.grievance_home.keywords")} />
          </Helmet>}

          {(!!tenant && tenant?.toString()?.includes(url?.subdomain?.vigilance)) && <Helmet>
            <title>{t("seo_friendly.vigilance_home.title")}</title>
            <meta name="description" content={t("seo_friendly.vigilance_home.desc")} />
            <meta name="keywords" content={t("seo_friendly.vigilance_home.keywords")} />
          </Helmet>}

          {(!!tenant && tenant?.toString()?.includes(url?.subdomain?.corporate)) && <Helmet>
            <meta name="keywords" content={t("seo_friendly.corporate_home.keywords")} />
          </Helmet>}

          {(!!tenant && tenant?.toString()?.includes(url?.subdomain?.chatbot)) && <Helmet>
            <title>{t("seo_friendly.chatbot_home.title")}</title>
            <meta name="description" content={t("seo_friendly.chatbot_home.desc")} />
            <meta name="keywords" content={t("seo_friendly.chatbot_home.keywords")} />
          </Helmet>}

          <Suspense fallback={<Loader />}>
            {!loader &&
              <Router>
                {webOpen && <OtherWebLink />}
                <Components />
              </Router>
            }
          </Suspense>
        </>
      </CacheBuster>
    </>
  );
};

export default MainRouter;
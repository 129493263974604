/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { AutoNew, Speaker, Warn } from "../svg/AllSvg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Captcha = ({ captchaValue, setCaptchaValue, captchaError, setCaptchaError, captchaExpirationTime, setCaptchaExpirationTime, captchaInput, setCaptchaInput }: any) => {
    const theme: any = useSelector((state: any) => state.ui.theme);
    const canvasRef = useRef(null);
    const { t } = useTranslation('common');

    const generateCaptcha = () => {
        const chars: any = process.env.REACT_APP_CAPTCHA_KEY;
        let captcha = "";
        for (let i = 0; i < 4; i++) {
            const randomChar = chars[Math.floor(Math.random() * chars.length)];
            captcha += randomChar;
        }
        setCaptchaInput("")
        return captcha;
    };

    const drawCaptcha = (captcha: any) => {
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Set canvas styles
        ctx.font = "28px Arial";
        ctx.fillStyle = theme === "dark" ? "white" : "black";
        ctx.fillText(captcha, 10, 25); // Draw the CAPTCHA text
    };

    const speakCaptcha = (captcha: any) => {
        const utterance = new SpeechSynthesisUtterance(captcha);
        utterance.lang = "en-US"; // Set language
        speechSynthesis.speak(utterance);
    };

    useEffect(() => {
        const newCaptcha = generateCaptcha();
        setCaptchaValue(newCaptcha);
        drawCaptcha(newCaptcha);
        // speakCaptcha(newCaptcha); 
    }, [theme]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const newCaptcha = generateCaptcha();
            setCaptchaValue(newCaptcha);
            drawCaptcha(newCaptcha);
            setCaptchaExpirationTime(Date.now() + (5 * 60 * 1000));
        }, captchaExpirationTime - Date.now());

        return () => clearTimeout(timer);
    }, [captchaExpirationTime]);

    const loadNewCaptcha = () => {
        const newCaptcha = generateCaptcha();
        setCaptchaValue(newCaptcha);
        drawCaptcha(newCaptcha);
    }

    return (
        <div className="gap-[10px] flex">
            <div>
                <div className="flex flex-col gap-[8px]">
                    <div className="bg-[#FFF] dark:bg-bgDarkDull rounded-[5px] w-fit h-[35px] xlx:h-[40px] flex items-center justify-center border dark:border-[#454545] border-[#D7D8DD]">
                        <canvas ref={canvasRef} width="100" height="40" className="flex items-center justify-center w-full h-full mt-[7px]"></canvas>
                    </div>
                    <div className="flex items-center gap-[4px]">
                        <button aria-label={t("aria_label.new_captcha")} title={t("aria_label.new_captcha")}  type="button" className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] dark:focus:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] dark:hover:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d]" onClick={loadNewCaptcha}>
                            <AutoNew />
                        </button>
                        <button aria-label={t("aria_label.audio_captcha")} title={t("aria_label.audio_captcha")} type="button" className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] dark:focus:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] dark:hover:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d]" onClick={() => speakCaptcha(captchaValue)}>
                            <Speaker />
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-[4px]">
                <input
                    type="text"
                    id="captchaInput"
                    name="captchaInput"
                    className="rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug max-w-[131px] dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull"
                    value={captchaInput}
                    onChange={(e: any) => { setCaptchaInput(e.target.value); setCaptchaError(""); }}
                    placeholder={t("auth.form.field.captcha")}
                    autoComplete="off"
                />
                {!!captchaError && <div className="flex gap-[6px] items-start"><Warn /><span className="text-danger text-xs font-medium">{captchaError}</span></div>}

            </div>
        </div>
    );
};

export default Captcha;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer } from "react-toastify";
import useAxios from "../../shared/hooks/use-axios";
import { url } from "../../shared/utils/url";
import { dataActions } from "../../shared/store/data-Slice";
import { errors } from "../../shared/utils/errors";
import { CloseBlack, SecondaryArr, Warn } from "../common/svg/AllSvg";
import SecondaryBtn from "../common/form/SecondaryBtn";
import PrimaryInput from "../common/form/PrimaryInput";
import SelectSecondaryComponent from "../common/form/SelectSecondaryComponent";
import { languages } from "../../shared/utils/languages";
import { components } from "react-select";

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SecondaryArr />
      </components.DropdownIndicator>
    )
  );
};

const AddNewWord = () => {
  const { t } = useTranslation('common');
  const createWord: boolean = useSelector((state: any) => state.data.createWord);
  const [submit, setSubmit] = useState<boolean>(false)
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref: any = useRef();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      word: '',
      lang: ''
    },
    validationSchema: yup.object().shape({
      word: yup.string().required(t("chatbot.add_new_word.word_validation")),
      lang: yup.object().required(t("chatbot.add_new_word.lang_validation"))
    }),
    onSubmit: async (values: any) => {
      //submit
      try {
        setSubmit(true)
        let payload: any = {
          word: values?.word,
          language: values?.lang?.value
        }
        const res: any = await useAxios.post((window.location.pathname.includes("/stop-words") ? url?.add_new_stop_word : url?.add_new_alert_word), payload);

        if (res && (res.status === 200 || res.status === 201)) {
          dispatch(dataActions.setChatbotApiCall(true));
          dispatch(dataActions.setCreateWord(false));
          !!params?.lang ? navigate(`/${params?.lang}${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`) : navigate(`${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`)
        }
      } catch (error) {
        errors(error)
        setSubmit(false)
        console.error("error while status change", error);
      }
    }
  });

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dataActions.setCreateWord(false));
        !!params?.lang ? navigate(`/${params?.lang}${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`) : navigate(`${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(dataActions.setCreateWord(false))
      !!params?.lang ? navigate(`/${params?.lang}${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`) : navigate(`${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`)
    }
  };

  return (
    <>
      {createWord &&
        <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
          <div className="flex justify-center items-center min-h-screen">
            <div className="m-[15px] mm:mx-auto w-full sm:w-[485px] h-auto relative bg-white rounded-[15px] shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)]" ref={ref}>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
              />

              <div className="w-full flex items-center justify-between gap-[15px] border-b border-[#DADADA] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                <h5 className="text-primaryText font-semibold">{t("chatbot.home.banner.new")}</h5>

                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e]" type="button" onClick={() => {
                  dispatch(dataActions.setCreateWord(false))
                  !!params?.lang ? navigate(`/${params?.lang}${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`) : navigate(`${window.location.pathname.includes("/stop-words") ? "/stop-words" : "/alert-words"}`)
                }}>
                  <CloseBlack />
                </button>
              </div>

              <form onSubmit={(e: any) => { e.preventDefault(); formik?.handleSubmit(); }} className="py-[16px] px-[22px] flex flex-col gap-[25px] xlx:gap-[30px]">
                <div className="flex flex-col gap-[4px]">
                  <PrimaryInput title={`${t("chatbot.add_new_word.word")}*`} name="word" type="text" placeholder={t("chatbot.add_new_word.word")} value={formik?.values?.word} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                  {formik?.touched?.word && formik?.errors?.word && <div className="flex gap-[6px] items-start"><Warn />
                    <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.word}</span>
                  </div>}
                </div>

                <div className="flex flex-col gap-[4px]">
                  <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("chatbot.add_new_word.lang")}*</h6>
                  <SelectSecondaryComponent
                    options={languages}
                    name="lang"
                    placeholder={t("chatbot.add_new_word.select_lang")}
                    isSearchable={false}
                    arrow={true}
                    components={{ DropdownIndicator }}
                    onChange={(e: any) => { formik.setFieldValue("lang", e); }}
                    onBlur={(e: any) => formik.setFieldTouched("lang", e)}
                    value={formik.values.lang}
                  />
                  {formik?.touched?.lang && formik?.errors?.lang && <div className="flex gap-[6px] items-start"><Warn />
                    <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.lang}</span>
                  </div>}
                </div>

                <div className="flex w-full items-end justify-end">
                  <SecondaryBtn title={t("chatbot.add_new_word.save")} disabled={submit} onClick={formik.handleSubmit} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                </div>
              </form>
            </div>
          </div>
        </div >}
    </>
  );
};

export default AddNewWord;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Arohatech, FacebookIcon, InstaIcon, LastUpdated, LinkedInIcon, TwitterIcon, VisitorNo } from "../svg/AllSvg";
import Logo from "../../../assets/images/footer-logo.png"
import DarkLogo from "../../../assets/images/dark-footer-logo.png"
import Maharashtra from "../../../assets/images/Seal_of_Maharashtra.svg 1.png"
import Government from "../../../assets/images/Mygov 1.png"
import BharatSarkar from "../../../assets/images/bharat-sarkar.png"
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { url } from "../../../shared/utils/url";
import useAxios from "../../../shared/hooks/use-axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { dataActions } from "../../../shared/store/data-Slice";

const Footer = ({ type }: any) => {
  const theme: any = useSelector((state: any) => state.ui.theme);
  const lang: string = useSelector((state: any) => state.ui.lang);
  const tenant: string = useSelector((state: any) => state.data.tenant);
  const [footerMenuData, setFooterMenuData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null)
  const { t } = useTranslation('common');
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const res: any = await useAxios.get(url.passenger_footer);

      if (res && res.status === 200) {
        setData(res?.data?.results)
      }
    } catch (error) {
      console.error("error while fetching footer data", error);
    }
  }

  useEffect(() => {
    type === "passenger" && getData();
    !!type && postVisitorCounter();
  }, [type])

  const handleNavigate = async (menu: any) => {
    if (menu?.title === "Careers" || menu?.title === "करियर") {
      const protocol = window.location.protocol;
      const www = window.location.host?.includes("www.") ? true : false;
      const host = www ? window.location.host?.replace("www.", "") : window.location.host;
      let currentHost: any = !!tenant ? host?.replace(`${tenant}.`, "") : host;
      const newUrl: any = tenant === url?.subdomain?.recruitment ? `${protocol}//${currentHost}` : `${protocol}//${url?.subdomain?.recruitment}.${currentHost}`;
      if (params?.lang) {
        window.location.href = `${newUrl}/${params?.lang}`;
      } else {
        window.location.href = newUrl;
      }
    } else if (menu?.title === "Vigilance" || menu?.title === "सतर्कता" || menu?.title === "दक्षता") {
      const protocol = window.location.protocol;
      const www = window.location.host?.includes("www.") ? true : false;
      const host = www ? window.location.host?.replace("www.", "") : window.location.host;
      let currentHost: any = !!tenant ? host?.replace(`${tenant}.`, "") : host;
      const newUrl: any = tenant === url?.subdomain?.vigilance ? `${protocol}//${currentHost}` : `${protocol}//${url?.subdomain?.vigilance}.${currentHost}`;
      if (params?.lang) {
        window.location.href = `${newUrl}/${params?.lang}`;
      } else {
        window.location.href = newUrl;
      }
    } else {
      // change live
      if (url?.env === "prod") {
        if (!!tenant) {
          let currentUrl: any = window.location.origin?.replace(`${tenant}.`, "")
          window.location.href = !!params?.lang ? `${currentUrl}/${params?.lang}` : `${currentUrl}/`;
        } else {
          !!params?.lang ? navigate(`/${params?.lang + menu?.url}`) : navigate(`${menu?.url}`)
        }
      } else {
        !!params?.lang ? navigate(`/${params?.lang + menu?.url}`) : navigate(`${menu?.url}`)
      }
    }
  }

  const getFooterMenu = async () => {
    try {
      setLoading(true)
      const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.menu}${type === "passenger" ? "passenger-footer/" : ""}`);

      if (res && res?.status === 200) {
        setFooterMenuData(res?.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("error while feching footer menu", error);
    }
  };

  useEffect(() => {
    (!!lang && !!type && type === "passenger") && getFooterMenu();
  }, [lang, type])

  const postVisitorCounter = async () => {
    try {
      const res: any = await useAxios.post(`${type === "passenger" ? (url?.passenger_visitor_count) : (url?.recruitment_visitor_count)}`, { counter: !!!localStorage.getItem("visitor_count") ? 1 : 0 });

      if (res && res?.status === 200) {
        localStorage.setItem("visitor_count", "1")
        getData()
      }
    } catch (error) {
      console.error("error while increase visitor counter", error);
    }
  };

  const handleLink = (item: any) => {
    dispatch(dataActions.setWebOpen(true));
    dispatch(dataActions.setLink(item));
  }

  return (
    <div id="footer" className="bg-[#F6F9FC] dark:bg-[#0B121A] w-full h-full flex flex-col">
      <div className="w-full container flex flex-col pb-[20px] lg:pb-[38px] pt-[30px] lg:pt-[75px]">
        <div className="w-full h-full flex justify-between gap-[20px] lg:flex-row flex-col">
          <div className="flex flex-col xsm:flex-row gap-[4px] xl:gap-[9px] lg:flex-col lg:w-auto w-full lg:justify-start justify-start xsm:justify-between">
            <div className="min-w-[160px] ml-[-28px]">
              <img src={theme === "dark" ? DarkLogo : Logo} alt={`${lang === "en" ? "Mumbai Metro Rail Corporation Limited" : lang === "hi" ? "मुंबई मेट्रो रेल कॉर्पोरेशन लिमिटेड" : "मुंबई मेट्रो रेल कॉर्पोरेशन मर्यादित"}`} className="w-[160px] h-[76px]" />
            </div>

            <div className="flex items-center gap-[14px]">
              <Link to="https://www.india.gov.in/" target="_blank" onClick={(e) => { handleLink("https://www.india.gov.in/"); e.preventDefault(); }} className="border border-transparent  hover:border-[#E5E5E5] hover:rounded-[6px] focus:border-[#E5E5E5] dark:hover:border-[#40434D] dark:focus:border-[#40434D] focus:rounded-[6px] p-[6px]" aria-label="Bharat Sarkar" title="Bharat Sarkar"><img src={BharatSarkar} alt="Bharat Sarkar" className="footer-icon" /></Link>
              <Link to="https://www.mygov.in/" target="_blank" onClick={(e) => { handleLink("https://www.mygov.in/"); e.preventDefault(); }} className="border border-transparent  hover:border-[#E5E5E5] hover:rounded-[6px] focus:border-[#E5E5E5] dark:hover:border-[#40434D] dark:focus:border-[#40434D] focus:rounded-[6px] p-[6px]" aria-label="My Government" title="My Government"><img src={Government} alt="My Government" /></Link>
              <Link to="https://maharashtra.gov.in/" target="_blank" onClick={(e) => { handleLink("https://maharashtra.gov.in/"); e.preventDefault(); }} className="border border-transparent  hover:border-[#E5E5E5] hover:rounded-[6px] focus:border-[#E5E5E5] dark:hover:border-[#40434D] dark:focus:border-[#40434D] focus:rounded-[6px] p-[6px]" aria-label="Seal of Maharashtra" title="Seal of Maharashtra"> <img src={Maharashtra} alt="Seal of Maharashtra" /></Link>
            </div>
          </div>

          {type === "recruitment" ?
            <div className="w-fit h-fit rounded-[10px] bg-white dark:bg-bgDarkDull p-[20px] flex items-center gap-[15px] xlx:gap-[20px] shadow-[0px_2px_4px_-2px_rgba(24,39,75,0.12),0px_4px_4px_-2px_rgba(24,39,75,0.08)] mb-[15px]">
              <img src={require("../../../assets/images/mail-icon.png")} alt="mail" className="w-[48px] xlx:w-[51px]" />
              <div className="flex flex-col gap-[2px]">
                <h5 className="text-primary font-bold">{t("recruitment.home.mail")}</h5>
                <h6 className="text-blackDull dark:text-textBlackDull font-medium"><Link to="mailto:recruitment.support@mmrcl.com" className="hover:underline focus:underline">recruitment.support@mmrcl.com</Link></h6>
              </div>

            </div> : <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-[20px] gap-x-[35px] xl:gap-x-[50px] mb-[15px]">
              {loading ?
                <>
                  {Array.isArray([1, 2, 3]) && [1, 2, 3]?.map((item: any, index: number) => (
                    <div className="flex flex-col gap-[15px] md:gap-[20px] pb-[10px] animate-pulse" key={index}>
                      <div className="w-[100px] h-[12px] bg-gray-200 rounded-full dark:bg-gray-700"></div>

                      {Array.isArray([1, 2, 3, 4]) && [1, 2, 3, 4]?.map((item: any, index: number) => (
                        <div className="max-w-[185px] mms:w-[185px] h-[12px] bg-gray-200 rounded-full dark:bg-gray-700" key={index}></div>
                      ))}
                    </div>
                  ))}
                </>
                : <>
                  {Array.isArray(footerMenuData) && footerMenuData?.filter((item: any, index: number) => index !== 1)?.map((item: any, index: number) => (
                    <div className="flex flex-col gap-[15px] md:gap-[20px] pb-[10px]" key={index}>
                      <h6 className="text-blackDull dark:text-textBlackDull dark:text-opacity-[0.9] font-medium pb-[10px]">{item?.title}</h6>

                      {Array.isArray(item?.subRoutes) && item?.subRoutes?.map((i: any, idx: number) => (
                        <React.Fragment key={idx}>
                          {
                            i?.open_in_new_tab ?
                              <Link to={i?.url} target='_blank' className="text-blackDark dark:text-textBlackDark font-medium hover:underline focus:underline" onClick={(e) => { if (i?.url_type === "external") { handleLink(i?.url); e.preventDefault(); } }}><h5>{i?.title}</h5></Link>
                              : <Link to={!!params?.lang ? `${i?.url === "/" ? `/${params?.lang}` : `/${params?.lang + i?.url}`}` : i?.url} className="text-blackDark dark:text-textBlackDark font-medium hover:underline focus:underline" onClick={() => handleNavigate(i)}><h5>{i?.title}</h5></Link>
                          }
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </>}
            </div>}

        </div>


        <div className="w-full h-full flex items-center justify-end gap-[6px]">
          <Link to="https://x.com/MumbaiMetro3" target="_blank" aria-label="Visit Twitter Page" title='Twitter' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white dark:hover:bg-bgDark dark:focus:bg-bgDark" onClick={(e) => { handleLink("https://x.com/MumbaiMetro3"); e.preventDefault(); }}><TwitterIcon className="fill-black dark:fill-white" /></Link>
          <Link to="https://www.facebook.com/mmrcmumbai" target="_blank" aria-label="Visit Facebook Page" title='Facebook' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white dark:hover:bg-bgDark dark:focus:bg-bgDark" onClick={(e) => { handleLink("https://www.facebook.com/mmrcmumbai"); e.preventDefault(); }}><FacebookIcon className="fill-[#473BF0] dark:fill-white" /></Link>
          <Link to="https://www.instagram.com/mumbaimetro3" target="_blank" aria-label="Visit Insta Page" title='Insta' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white dark:hover:bg-bgDark dark:focus:bg-bgDark" onClick={(e) => { handleLink("https://www.instagram.com/mumbaimetro3"); e.preventDefault(); }}><InstaIcon className="fill-[#161C2D] dark:fill-white" /></Link>
          <Link to="https://in.linkedin.com/company/mumbai-metro-rail-corporation" target="_blank" aria-label="Visit LinkedIn Page" title='LinkedIn' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white dark:hover:bg-bgDark dark:focus:bg-bgDark" onClick={(e) => { handleLink("https://in.linkedin.com/company/mumbai-metro-rail-corporation"); e.preventDefault(); }}><LinkedInIcon className="fill-[#161C2D] dark:fill-white" /></Link>
        </div>
      </div>

      <div className="border-t border-[#E7E9ED] dark:border-[#40434D] px-[15px] sm:px-[40px] xl:px-[80px] py-[10px] w-full flex items-center mm:justify-between gap-[10px] mm:gap-[15px] xl:gap-[18px] mm:flex-row flex-col">
        <div className="flex items-center gap-[12px] xl:gap-[18px] xxs:flex-row flex-col">
          <h6 className="text-blackDull dark:text-textBlackDull font-semibold hidden xlsm:block dark:text-opacity-[0.8]">© {new Date().getFullYear()} <span className="text-primaryText dark:text-textBlackDull dark:text-opacity-[1]">{t("passenger.home.footer.mmrcl")}</span> {t("passenger.home.footer.all_rights")}</h6>

          <div className="flex items-center gap-[8px] xl:gap-[10px]">
            <div className="w-[15px] h-[16px] md:w-auto md:h-auto">
              <LastUpdated className="w-[15px] h-[16px] md:w-auto md:h-auto fill-primaryText dark:fill-textBlackDull" />
            </div>

            <p className="uppercase text-primaryText font-semibold dark:text-textBlackDull">{t("passenger.home.footer.last_updated")} : {type === "passenger" ? (!!data?.last_updated_date ? moment(data?.last_updated_date).format('MMM DD, YYYY') : "-") : (!!data?.updated_at ? moment(data?.updated_at).format('MMM DD, YYYY') : "-")}</p>
          </div>

          <div className="flex items-center gap-[8px] xl:gap-[10px]">
            <div className="w-[22px] h-[10px] md:w-auto md:h-auto">
              <VisitorNo className="w-[22px] h-[10px] md:w-auto md:h-auto fill-primaryText dark:fill-textBlackDull" />
            </div>

            <p className="uppercase text-primaryText font-semibold dark:text-textBlackDull">{t("passenger.home.footer.visitor_no")} {!!data?.visitors_count ? data?.visitors_count : "-"}</p>
          </div>
        </div>

        <div className="w-full xlsm:w-auto flex items-center justify-between gap-[12px]">
          <h6 className="text-blackDull font-semibold block xlsm:hidden break-word text-center dark:text-textBlackDull dark:text-opacity-[0.8]">© {new Date().getFullYear()} <span className="text-primaryText dark:text-textBlackDull dark:text-opacity-[1]">{t("passenger.home.footer.mmrcl")}</span> {t("passenger.home.footer.all_rights")}</h6>

          <div className="flex items-center xsm:gap-[8px] md:gap-[15px] xsm:flex-row flex-col">
            <h6 className="text-blackDull font-semibold order-2 xsm:order-1 text-center  dark:text-textBlackDull">{t("passenger.home.footer.designed_by")}</h6>

            <Link to="https://www.arohatech.com/" aria-label="Visit Arohatech Page" target="_blank" className="min-w-[108px] order-1 xsm:order-2 border border-transparent  hover:border-[#E5E5E5] hover:rounded-[6px] focus:border-[#E5E5E5] focus:rounded-[6px] p-[6px] dark:hover:border-[#40434D] dark:focus:border-[#40434D]" onClick={(e) => { handleLink("https://www.arohatech.com/"); e.preventDefault(); }}>
              <Arohatech />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { removeCookie } from '../utils/removeCookie';

interface AuthState {
    isLogin: boolean,
    user: any,
    adminUser: any,
    adminIsLogin: boolean
}

const initialState: AuthState = {
    isLogin: Cookies.get("userSessionTimeout") as any ? true : false,
    user: (Cookies.get('userdetail') as any) ? JSON.parse(Cookies.get('userdetail') as any) : null,
    adminIsLogin: !!JSON.parse(localStorage.getItem("adminSessionTimeout") as any) ? true : false,
    adminUser: JSON.parse(localStorage.getItem('admindetail') as any) || null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.isLogin = true;
        },
        logout: (state) => {
            if (window?.location?.pathname.includes("/admin")) {
                state.adminIsLogin = false;
                localStorage.removeItem("adminSessionTimeout");
                localStorage.removeItem("admindetail");
                localStorage.removeItem("admintoken");
            } else {
                state.isLogin = false;
                removeCookie();
            }
            localStorage.removeItem('chat')
        },
        userDetail: (state, action) => {
            state.user = action.payload;
        },
        adminLogin: (state) => {
            state.adminIsLogin = true;
        },
        adminDetail: (state, action) => {
            state.adminUser = action.payload;
        },
    }
});

export default authSlice;
export const authActions = authSlice.actions;
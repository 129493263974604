/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { Route, Routes } from "react-router-dom";
import LanguageChange from "../../components/common/layout/LanguageChange";
import ChatbotLayout from "./ChatbotLayout";
const Dashboard = React.lazy(() => import("../../pages/chatbot/dashboard/Dashboard"));
const Queries = React.lazy(() => import("../../pages/chatbot/queries/Queries"));
const StopWords = React.lazy(() => import("../../pages/chatbot/stopWords/StopWords"));
const AlertWords = React.lazy(() => import("../../pages/chatbot/alert-words/AlertWords"));
const Alerts = React.lazy(() => import("../../pages/chatbot/alerts/Alerts"));
const Login = React.lazy(() => import("../../pages/chatbot/auth/Login"));
const Profile = React.lazy(() => import("../../pages/passenger/profile/Profile"));
const NotFound = React.lazy(() => import("../../pages/common/notFound/NotFound"));

const Chatbot = () => {
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const langOpen: boolean = useSelector((state: any) => state.data.langOpen);
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
        }
    }, [window.location.hostname])

    useEffect(() => {
        if (langOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [langOpen]);

    return (
        <>
            {langOpen && <LanguageChange />}
            <Routes>
                {!isLogin ? <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/:lang/login" element={<Login />} />
                    <Route path="*" element={<Login />} />
                </> :
                    <>
                        <Route element={<ChatbotLayout />}>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/:lang" element={<Dashboard />} />
                            <Route path="/queries/:status" element={<Queries />} />
                            <Route path="/:lang/queries/:status" element={<Queries />} />
                            <Route path="/stop-words" element={<StopWords />} />
                            <Route path="/:lang/stop-words" element={<StopWords />} />
                            <Route path="/alert-words" element={<AlertWords />} />
                            <Route path="/:lang/alert-words" element={<AlertWords />} />
                            <Route path="/alerts" element={<Alerts />} />
                            <Route path="/:lang/alerts" element={<Alerts />} />
                            <Route path="/stop-words/add-new-word" element={<StopWords popup="add-new-word" />} />
                            <Route path="/:lang/stop-words/add-new-word" element={<StopWords popup="add-new-word" />} />
                            <Route path="/alert-words/add-new-word" element={<AlertWords popup="add-new-word" />} />
                            <Route path="/:lang/alert-words/add-new-word" element={<AlertWords popup="add-new-word" />} />
                            <Route path="/:lang/profile" element={<Profile type="chatbot" />} />
                            <Route path="/profile" element={<Profile type="chatbot" />} />
                        </Route>
                        <Route path="/:lang/404" element={<NotFound />} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                    </>
                }
            </Routes>
        </>
    );
};

export default Chatbot;
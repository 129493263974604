import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Delete, GroupClose, Warn } from '../../../common/svg/AllSvg';

const AadharDrivingAttachment = ({ files1, setFiles1, uploadProgress1, setUploadProgress1, disable1, setDisable1, err1, setErr1, simulateChunkUpload, submit }: any) => {
  const { t } = useTranslation('common');

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      setErr1("")
      if (!acceptedFiles[0]?.name?.match(/\.(PDF|pdf|Doc|doc|docx|DOCX|png|PNG|jpg|JPG|jpeg|JPEG)$/)) {
        setErr1(t("passenger.lost_found.report_form.validation.valid_file"));
        return true;
      }

      if (acceptedFiles[0].size > (5 * 1000 * 1024)) {
        setErr1(t("passenger.lost_found.report_form.validation.size_err"));
        return;
      }
      setUploadProgress1(0);
      setDisable1(true)
      setFiles1([acceptedFiles[0]])
      setErr1("")

      let uploadFile = acceptedFiles[0];
      if (uploadFile) {
        const chunkSize = 1024 * 1024; // 1MB chunk size
        const totalChunks = Math.ceil(uploadFile.size / chunkSize);
        let currentChunk = 0;

        const uploadNextChunk = () => {
          const start = currentChunk * chunkSize;
          const end = Math.min(start + chunkSize, uploadFile.size);
          const chunk = uploadFile.slice(start, end);

          simulateChunkUpload(chunk)
            .then(() => {
              currentChunk++;

              if (currentChunk < totalChunks) {
                const progress = Math.floor((currentChunk / totalChunks) * 100);
                setUploadProgress1(progress);

                uploadNextChunk();
              } else {
                setUploadProgress1(100);
                setDisable1(false)
              }
            })
            .catch((error: any) => {
              console.error('Error uploading chunk', error);
            });
        };

        uploadNextChunk();
      }
    }
  });

  const removeFile1 = (idx: number) => {
    let arr: any[] = [];
    for (let i = 0; i < files1?.length; i++) {
      const el = files1[i];

      if (idx !== i) {
        arr.push(el)
      }
    }

    setFiles1(arr)
  };

  const thumbs1 = files1?.map((file: any, idx: number) => (
    <div key={idx} className='w-full flex items-center justify-between px-[15px] xlx:px-[19px] border border-[#D7D8DD] rounded-full bg-[#F5F5F5] h-[35px] xlx:h-[40px] gap-[15px]'>
      <Link to={!!file?.url ? file?.url : URL.createObjectURL(file)} download={!!file?.url ? file?.url : URL.createObjectURL(file)} target="_blank" className="text-blackDull text-sm leading-snug font-normal truncate hover:underline focus:underline">{file?.name}</Link>

      {(uploadProgress1 !== 100 && (idx + 1 === files1?.length)) ?
        <div className="flex items-center gap-[5px]">
          <h1 className="text-blackDull text-sm leading-snug font-normal">{t("passenger.lost_found.report_form.form.uploading")} {uploadProgress1}%</h1>
          <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]" onClick={() => removeFile1(idx)} type="button">
            <GroupClose />
          </button>
        </div> :
        <div className="flex items-center gap-[5px] whitespace-nowrap">
          <div {...getRootProps()} onClick={(e) => e.stopPropagation}>
            <input {...getInputProps()} />
            <button type="button" onClick={open} className="text-blackDull text-sm leading-snug font-semibold disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable1}> {t("passenger.lost_found.report_form.form.replace")}</button>
          </div>
          <div className="border-r h-[16px] border-primary pl-[5px]"></div>
          <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} onClick={() => removeFile1(idx)} type="button" className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]">
            <Delete />
          </button>
        </div>
      }
    </div>
  ));

  return (
    <div className="flex flex-col gap-[4px] w-full">
      <h6 className="text-blackDark font-medium">{t("grievance.new_complaint.field.aadhaar_driving_attachment")}*</h6>

      {files1?.length === 0 && <div className="flex flex-col gap-[4px]">
        <div className="h-[35px] xlx:h-[40px] w-full rounded-full border border-[#D7D8DD] bg-[#F5F5F5] flex items-center justify-between pl-[5px] pr-[18px] xlx:pr-[22px]">
          <div {...getRootProps()} onClick={(e) => e.stopPropagation} className={`rounded-full ${(disable1 || files1?.length === 1 || submit) ? "" : "focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]"}`}>
            <input {...getInputProps()} />
            <button type="button" tabIndex={-1} onClick={open} className="h-[25px] xlx:h-[30px] flex items-center justify-center w-fit px-[15px] xlx:px-[19px] text-white font-bold text-sm !leading-tight rounded-full bg-primary disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable1 || files1?.length === 1 || submit}> {t("passenger.lost_found.report_form.form.upload_file")}</button>
          </div>

          <h6 className="text-blackDull font-normal !leading-tight">{t("grievance.new_complaint.field.type")} <span className="text-primaryText font-semibold !leading-tight"> | {t("passenger.lost_found.report_form.form.size")}</span></h6>
        </div>
        {!!err1 && <div className="flex gap-[6px] mt-[4px]"><Warn />
          <span className="text-danger text-xs font-medium">{err1}</span>
        </div>}
      </div>}
      {files1?.length > 0 && <aside className='w-full flex flex-col gap-[12px]'>{thumbs1}</aside>}
    </div>
  );
};

export default AadharDrivingAttachment;
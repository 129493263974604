/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { dataActions } from '../../../shared/store/data-Slice';
import { Route, Routes } from 'react-router-dom';
import CorporateLayout from './CorporateLayout';
const Home = React.lazy(() => import("../../../pages/corporate/corporate/home/Home"));
const PhotoGallery = React.lazy(() => import("../../../pages/corporate/corporate/photoGallery/PhotoGallery"));
const VideoGallery = React.lazy(() => import("../../../pages/corporate/corporate/videoGallery/VideoGallery"));
const News = React.lazy(() => import("../../../pages/corporate/corporate/news/News"));
const PressReleases = React.lazy(() => import("../../../pages/corporate/corporate/pressReleases/PressReleases"));
const Tenders = React.lazy(() => import("../../../pages/corporate/corporate/tenders/Tenders"));
const Content = React.lazy(() => import("../../../pages/passenger/content/Content"));
const AboutMMRC = React.lazy(() => import("../../../pages/corporate/corporate/aboutMMRC/AboutMMRC"));
const Search = React.lazy(() => import("../../../pages/passenger/search/Search"));
const ContactUs = React.lazy(() => import("../../../pages/common/contactUs/ContactUs"));
const ScreenReaderAccess = React.lazy(() => import("../../../pages/common/screenReaderAccess/ScreenReaderAccess"));
const SiteMap = React.lazy(() => import("../../../pages/common/sitemap/SiteMap"));
const OtherMetros = React.lazy(() => import("../../../pages/corporate/corporate/otherMetros/OtherMetros"));
const PublicNotice = React.lazy(() => import("../../../pages/corporate/corporate/publicNotice/PublicNotice"));
const LotteryResult = React.lazy(() => import('../../../pages/corporate/corporate/lotteryResult/LotteryResult'));
const VigilanceAwareness = React.lazy(() => import("../../../pages/corporate/corporate/vigilanceAwareness/VigilanceAwareness"));
const IllustrativeDesigns = React.lazy(() => import("../../../pages/corporate/corporate/illustrativeDesigns/IllustrativeDesigns"));
const PublicConsultation = React.lazy(() => import("../../../pages/corporate/corporate/publicConsultation/PublicConsultation"));
const ProjectTimeline = React.lazy(() => import("../../../pages/corporate/corporate/projectTimeline/ProjectTimeline"));
const ProjectRoute = React.lazy(() => import("../../../pages/corporate/corporate/projectRoute/ProjectRoute"));
const BoardOfDirectors = React.lazy(() => import("../../../pages/corporate/corporate/boardOfDirectors/BoardOfDirectors"));
const TopManagementTeam = React.lazy(() => import("../../../pages/corporate/corporate/topManagementTeam/TopManagementTeam"));
const Properties = React.lazy(() => import("../../../pages/corporate/corporate/properties/Properties"));
const NotFound = React.lazy(() => import("../../../pages/common/notFound/NotFound"));
const CommingSoon = React.lazy(() => import("../../../pages/common/comingSoon/ComingSoon"));

const Corporate = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
        }
    }, [window.location.hostname])

    return (
        <>
            <Routes>
                <Route element={<CorporateLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/:lang" element={<Home />} />
                    <Route path="/photo-gallery" element={<PhotoGallery />} />
                    <Route path="/:lang/photo-gallery" element={<PhotoGallery />} />
                    <Route path="/video-gallery" element={<VideoGallery />} />
                    <Route path="/:lang/video-gallery" element={<VideoGallery />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/:lang/news" element={<News />} />
                    <Route path="/press-releases" element={<PressReleases />} />
                    <Route path="/:lang/press-releases" element={<PressReleases />} />
                    <Route path="/tenders" element={<Tenders />} />
                    <Route path="/:lang/tenders" element={<Tenders />} />
                    <Route path="/tenders/:category" element={<Tenders />} />
                    <Route path="/:lang/tenders/:category" element={<Tenders />} />
                    <Route path="/tenders/:category/:subcategory" element={<Tenders />} />
                    <Route path="/:lang/tenders/:category/:subcategory" element={<Tenders />} />
                    <Route path="/coming-soon" element={<CommingSoon type="corporate" />} />
                    <Route path="/:lang/coming-soon" element={<CommingSoon type="corporate" />} />
                    <Route path="/corporate/pages/other-metros" element={<OtherMetros />} />
                    <Route path="/:lang/corporate/pages/other-metros" element={<OtherMetros />} />
                    <Route path="/corporate/pages/vigilance-awareness" element={<VigilanceAwareness />} />
                    <Route path="/:lang/corporate/pages/vigilance-awareness" element={<VigilanceAwareness />} />
                    <Route path="/corporate/pages/illustrative-designs" element={<IllustrativeDesigns />} />
                    <Route path="/:lang/corporate/pages/illustrative-designs" element={<IllustrativeDesigns />} />
                    <Route path="/corporate/pages/public-notices" element={<PublicNotice />} />
                    <Route path="/:lang/corporate/pages/public-notices" element={<PublicNotice />} />
                    <Route path="/corporate/pages/lottery-result" element={<LotteryResult />} />
                    <Route path="/:lang/corporate/pages/lottery-result" element={<LotteryResult />} />
                    <Route path="/corporate/pages/public-consultation" element={<PublicConsultation />} />
                    <Route path="/:lang/corporate/pages/public-consultation" element={<PublicConsultation />} />
                    <Route path="/corporate/pages/project-timeline" element={<ProjectTimeline />} />
                    <Route path="/:lang/corporate/pages/project-timeline" element={<ProjectTimeline />} />
                    <Route path="/corporate/pages/project-route" element={<ProjectRoute />} />
                    <Route path="/:lang/corporate/pages/project-route" element={<ProjectRoute />} />
                    <Route path="/corporate/pages/board-of-directors" element={<BoardOfDirectors />} />
                    <Route path="/:lang/corporate/pages/board-of-directors" element={<BoardOfDirectors />} />
                    <Route path="/corporate/pages/top-management-team" element={<TopManagementTeam />} />
                    <Route path="/:lang/corporate/pages/top-management-team" element={<TopManagementTeam />} />
                    <Route path="/corporate/pages/:slug" element={<Content type="corporate" />} />
                    <Route path="/:lang/corporate/pages/:slug" element={<Content type="corporate" />} />
                    <Route path="/search/:search" element={<Search type="corporate" />} />
                    <Route path="/:lang/search/:search" element={<Search type="corporate" />} />
                    <Route path="/contact-us" element={<ContactUs type="corporate" />} />
                    <Route path="/:lang/contact-us" element={<ContactUs type="corporate" />} />
                    <Route path="/screen-reader-access" element={<ScreenReaderAccess type="corporate" />} />
                    <Route path="/:lang/screen-reader-access" element={<ScreenReaderAccess type="corporate" />} />
                    <Route path="/site-map" element={<SiteMap type="corporate" />} />
                    <Route path="/:lang/site-map" element={<SiteMap type="corporate" />} />
                    <Route path="/about-us" element={<AboutMMRC />} />
                    <Route path="/:lang/about-us" element={<AboutMMRC />} />
                    <Route path="/properties" element={<Properties />} />
                    <Route path="/:lang/properties" element={<Properties />} />
                </Route>
                <Route path="/:lang/404" element={<NotFound />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default Corporate;
import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import SearchPopup from '../../../components/common/layout/SearchPopup';
import CorporateFooter from '../../../components/common/layout/CorporateFooter';
import LanguageChange from '../../../components/common/layout/LanguageChange';

const CorporateLayout = () => {
    const searchOpen: boolean = useSelector((state: any) => state.data.searchOpen);
    const langOpen: boolean = useSelector((state: any) => state.data.langOpen);
    const location = useLocation();

    useEffect(() => {
        if (searchOpen || langOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [searchOpen, langOpen]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {searchOpen && <SearchPopup />}
            {langOpen && <LanguageChange />}
            <Outlet />
            <CorporateFooter />
        </>
    );
};

export default CorporateLayout;
/* eslint-disable react-hooks/exhaustive-deps */
import DarkLogo from "../../../assets/images/dark-logo.svg"
import DarkLogo1 from "../../../assets/images/dark-logo1.svg"
import DarkLogo2 from "../../../assets/images/dark-logo2.svg"
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../../shared/utils/url";
import useAxios from "../../../shared/hooks/use-axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dataActions } from "../../../shared/store/data-Slice";
import { Arohatech, FacebookIcon, InstaIcon, LastUpdated, LinkedInIcon, TwitterIcon, VisitorNo } from "../svg/AllSvg";
import moment from "moment";

const CorporateFooter = () => {
    const lang: string = useSelector((state: any) => state.ui.lang);
    const tenant: string = useSelector((state: any) => state.data.tenant);
    const [footerMenuData, setFooterMenuData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>(null)
    const { t } = useTranslation('common');
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getData = async () => {
        try {
            const res: any = await useAxios.get(url?.corporate_footer);

            if (res && res.status === 200) {
                setData(res?.data?.results)
            }
        } catch (error) {
            console.error("error while fetching footer data", error);
        }
    }

    useEffect(() => {
        getData();
        postVisitorCounter();
    }, [])

    const handleNavigate = async (menu: any, e: any) => {
        if (menu?.url === "/map") {
            e.preventDefault();
            const protocol = window.location.protocol;
            const www = window.location.host?.includes("www.") ? true : false;
            const host = www ? window.location.host?.replace("www.", "") : window.location.host;
            let currentHost: any = !!tenant ? host?.replace(`${tenant}.`, "") : host;

            const newUrl: any = url?.env === "prod" ? `${protocol}//${currentHost}` : `${protocol}//${url?.subdomain?.main}.${currentHost}`;
            if (params?.lang) {
                window.location.href = `${newUrl}/${params?.lang}${menu?.url}`;
            } else {
                window.location.href = `${newUrl}${menu?.url}`;
            }
        }
    }

    const getFooterMenu = async () => {
        try {
            setLoading(true)
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.menu}corporate-footer/`);

            if (res && res?.status === 200) {
                setFooterMenuData(res?.data)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("error while feching footer menu", error);
        }
    };

    useEffect(() => {
        !!lang && getFooterMenu();
    }, [lang])

    const postVisitorCounter = async () => {
        try {
            const res: any = await useAxios.post(`${url?.corporate_visitor_count}`, { counter: !!!localStorage.getItem("visitor_count") ? 1 : 0 });

            if (res && res?.status === 200) {
                localStorage.setItem("visitor_count", "1")
                getData()
            }
        } catch (error) {
            console.error("error while increase visitor counter", error);
        }
    };

    const handleLink = (item: any) => {
        dispatch(dataActions.setWebOpen(true));
        dispatch(dataActions.setLink(item));
    }

    return (
        <div className="w-full mt-[-30px] lg:mt-[-50px] rounded-t-[30px] xlx:rounded-t-[40px] bg-[#162340] relative z-[1]">
            <div className="w-full container py-[30px] xl:py-[50px] border-b border-white border-opacity-[0.2] flex xsm:items-center gap-[20px] xsm:gap-[15px] xsm:justify-between xsm:flex-row flex-col">
                <h2 className="text-white font-bold max-w-[515px] xlx:max-w-[525px]">{t("corporate.footer.title")}</h2>
                <button onClick={() => !!params?.lang ? navigate(`/${params?.lang}/contact-us`) : navigate(`/contact-us`)} className="bg-white h-[40px] xlx:h-[45px] rounded-[10px] px-[18px] xlx:px-[29px] flex items-center justify-center text-primaryText text-base font-bold hover:shadow-[0_0_0_2px_#898989] focus:shadow-[0_0_0_2px_#898989] w-fit whitespace-nowrap uppercase">{t("corporate.footer.contact_btn")}</button>
            </div>

            <div className="w-full container flex flex-col pb-[20px] xl:pb-[38px] pt-[30px] xl:pt-[50px]">
                <div className="w-full h-full flex justify-between gap-[30px] xl:gap-[20px] xl:flex-row flex-col">
                    <div className="flex flex-col mms:flex-row gap-[15px] xl:gap-[35px] xl:flex-col xl:w-auto w-full xl:justify-start justify-start xsm:justify-between">
                        <div className="ml-[-8px]">
                            <img src={lang === "en" ? DarkLogo : lang === "hi" ? DarkLogo1 : DarkLogo2} alt={`${lang === "en" ? "Mumbai Metro Rail Corporation Limited" : lang === "hi" ? "मुंबई मेट्रो रेल कॉर्पोरेशन लिमिटेड" : "मुंबई मेट्रो रेल कॉर्पोरेशन मर्यादित"}`}  className="h-[62px]" />
                        </div>

                        <h6 className="text-white font-medium mms:max-w-[310px] xlx:max-w-[344px]">{t("corporate.footer.desc")}</h6>
                    </div>

                    <div className="grid grid-cols-2 xsm:grid-cols-3 msm:grid-cols-5 xl:flex xl:flex-wrap gap-y-[20px] gap-x-[30px] msm:gap-[20px] mb-[15px]">
                        {loading ?
                            <>
                                {Array.isArray([1, 2, 3, 4, 5]) && [1, 2, 3, 4, 5]?.map((item: any, index: number) => (
                                    <div className="flex flex-col gap-[15px] md:gap-[18px] pb-[10px] animate-pulse xlx:w-[160px] xlx:min-w-[160px] xl:w-[145px] xl:min-w-[144px]" key={index}>
                                        <div className="w-[100px] h-[12px] bg-gray-700 rounded-full mb-[7px]"></div>

                                        {Array.isArray([1, 2, 3, 4, 5]) && [1, 2, 3, 4, 5]?.map((item: any, index: number) => (
                                            <div className="max-w-[150px] mms:w-[150px] h-[12px] bg-gray-700 rounded-full mb-[10px]" key={index}></div>
                                        ))}
                                    </div>
                                ))}
                            </>
                            : <>
                                {Array.isArray(footerMenuData) && footerMenuData?.map((item: any, index: number) => (
                                    <div className="flex flex-col gap-[12px] md:gap-[15px] pb-[10px] xlx:w-[160px] xlx:min-w-[160px] xl:w-[145px] xl:min-w-[144px]" key={index}>
                                        <h6 className="text-white text-opacity-[0.7] font-medium pb-[8px]">{item?.title}</h6>

                                        {Array.isArray(item?.subRoutes) && item?.subRoutes?.map((i: any, idx: number) => (
                                            <React.Fragment key={idx}>
                                                {
                                                    i?.open_in_new_tab ?
                                                        <Link to={i?.url} target='_blank' className="text-white font-medium hover:underline focus:underline" onClick={(e) => { if (i?.url_type === "external") { handleLink(i?.url); e.preventDefault(); } }}><h5>{i?.title}</h5></Link>
                                                        : <Link to={!!params?.lang ? `${i?.url === "/" ? `/${params?.lang}` : `/${params?.lang + i?.url}`}` : i?.url} className="text-white font-medium hover:underline focus:underline" onClick={(e: any) => handleNavigate(i, e)}><h5>{i?.title}</h5></Link>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </div>
                                ))}
                            </>}
                    </div>

                </div>


                <div className="w-full h-full flex items-center justify-end gap-[6px]">
                    <Link to="https://x.com/MumbaiMetro3" target="_blank" aria-label="Visit Twitter Page" title='Twitter' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-bgDark focus:bg-bgDark" onClick={(e) => { handleLink("https://x.com/MumbaiMetro3"); e.preventDefault(); }}><TwitterIcon className="fill-white" /></Link>
                    <Link to="https://www.facebook.com/mmrcmumbai" target="_blank" aria-label="Visit Facebook Page" title='Facebook' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-bgDark focus:bg-bgDark" onClick={(e) => { handleLink("https://www.facebook.com/mmrcmumbai"); e.preventDefault(); }}><FacebookIcon className="fill-white" /></Link>
                    <Link to="https://www.instagram.com/mumbaimetro3" target="_blank" aria-label="Visit Insta Page" title='Insta' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-bgDark focus:bg-bgDark" onClick={(e) => { handleLink("https://www.instagram.com/mumbaimetro3"); e.preventDefault(); }}><InstaIcon className="fill-white" /></Link>
                    <Link to="https://in.linkedin.com/company/mumbai-metro-rail-corporation" target="_blank" aria-label="Visit LinkedIn Page" title='LinkedIn' className="min-w-[30px] h-[30px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-bgDark focus:bg-bgDark" onClick={(e) => { handleLink("https://in.linkedin.com/company/mumbai-metro-rail-corporation"); e.preventDefault(); }}><LinkedInIcon className="fill-white" /></Link>
                </div>
            </div>

            <div className="border-t border-white border-opacity-[0.2] px-[15px] sm:px-[40px] xl:px-[80px] py-[10px] w-full flex items-center mm:justify-between gap-[10px] mm:gap-[15px] xl:gap-[18px] mm:flex-row flex-col">
                <div className="flex items-center gap-[12px] xl:gap-[18px] xxs:flex-row flex-col">
                    <h6 className="text-white font-semibold hidden xlsm:block">© {new Date().getFullYear()} <span className="text-white">{t("passenger.home.footer.mmrcl")}</span> {t("passenger.home.footer.all_rights")}</h6>

                    <div className="flex items-center gap-[8px] xl:gap-[10px]">
                        <div className="w-[15px] h-[16px] md:w-auto md:h-auto">
                            <LastUpdated className="w-[15px] h-[16px] md:w-auto md:h-auto fill-white" />
                        </div>

                        <p className="uppercase text-white font-semibold">{t("passenger.home.footer.last_updated")} : {(!!data?.last_updated_date ? moment(data?.last_updated_date).format('MMM DD, YYYY') : "-")}</p>
                    </div>

                    <div className="flex items-center gap-[8px] xl:gap-[10px]">
                        <div className="w-[22px] h-[10px] md:w-auto md:h-auto">
                            <VisitorNo className="w-[22px] h-[10px] md:w-auto md:h-auto fill-white" />
                        </div>

                        <p className="uppercase text-white font-semibold">{t("passenger.home.footer.visitor_no")} {!!data?.visitors_count ? data?.visitors_count : "-"}</p>
                    </div>
                </div>

                <div className="w-full xlsm:w-auto flex items-center justify-between gap-[12px]">
                    <h6 className="text-white font-semibold block xlsm:hidden break-word text-center">© {new Date().getFullYear()} <span className="text-white">{t("passenger.home.footer.mmrcl")}</span> {t("passenger.home.footer.all_rights")}</h6>

                    <div className="flex items-center xsm:gap-[8px] md:gap-[15px] xsm:flex-row flex-col">
                        <h6 className="text-white font-semibold order-2 xsm:order-1 text-center">{t("passenger.home.footer.designed_by")}</h6>

                        <Link to="https://www.arohatech.com/" aria-label="Visit Arohatech Page" target="_blank" className="min-w-[108px] order-1 xsm:order-2 border border-transparent rounded-[6px] p-[6px] hover:border-[#40434D] focus:border-[#40434D]" onClick={(e) => { handleLink("https://www.arohatech.com/"); e.preventDefault(); }}>
                            <Arohatech />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CorporateFooter;
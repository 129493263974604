import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CloseBtn } from "../svg/AllSvg";
import { dataActions } from "../../../shared/store/data-Slice";

const OtherWebLink = () => {
    const webOpen: boolean = useSelector((state: any) => state.data.webOpen);
    const link: string = useSelector((state: any) => state.data.link);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setWebOpen(false))
        }
    };

    const handleLink = () => {
        if (!!link) {
            window.open(link, '_blank');
            dispatch(dataActions.setWebOpen(false));
            dispatch(dataActions.setLink(null));
        }
    }

    return (
        <>
            {webOpen &&
                <div className="fixed inset-0 z-[40] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]" onClick={() => dispatch(dataActions.setWebOpen(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto dark:bg-bgDarkDull bg-white rounded-[12px] w-full h-auto xsm:w-[400px] relative p-[24px] shadow-[0px_20px_24px_-4px_rgba(16,24,40_0.08),0px_8px_8px_-4px_rgba(16,24,40,0.03)]">
                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px] rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" onClick={() => dispatch(dataActions.setWebOpen(false))}>
                                <CloseBtn className="min-w-[23px]" />
                            </button>

                            <div className="flex flex-col gap-[15px] xlx:gap-[20px]">
                                <img src={require("../../../assets/images/web.png")} alt="web" className="w-fit h-fit ml-[-5px]" />
                                <h4 className="text-primary font-bold">{t("website.title")}</h4>
                                <h6 className="text-blackDull dark:text-textBlackDull font-semibold">{t("website.desc")}</h6>

                                <div className="flex items-center gap-[12px] mt-[15px] xlx:mt-[20px]">
                                    <button className="xlx:h-[44px] h-[40px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-base font-medium text-blackDull bg-white px-[18px] w-full border border-[#D0D5DD] focus:shadow-[0_0_0_3px_#D0D5DD5e] hover:shadow-[0_0_0_3px_#D0D5DD5e]" onClick={() => dispatch(dataActions.setWebOpen(false))}>{t("website.go_back")}</button>
                                    <button className="xlx:h-[44px] h-[40px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-base font-medium text-white bg-blackDull px-[18px] w-full focus:shadow-[0_0_0_3px_#4e566d5e] hover:shadow-[0_0_0_3px_#4e566d5e] dark:focus:shadow-[0_0_0_3px_#ffffff5e] dark:hover:shadow-[0_0_0_3px_#ffffff5e]" autoFocus onClick={handleLink}>{t("website.yes")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default OtherWebLink;